<template>
  <div style="width: 100%; height: 100%; padding: 20px">
    <div
      style="display: flex; justify-content: space-between; margin-bottom: 10px"
    >
      <b-button variant="outline-success" to="/ara">
        <b-icon icon="arrow-left" aria-hidden="true"></b-icon>
        Aramaya dön
      </b-button>
    </div>
    <div>
      <table style="width: 100%">
        <thead>
          <th>Kullanıcı</th>
          <th>Aranan kelime</th>
          <th>Tarih</th>
          <th style="text-align: center">Excel export</th>
        </thead>
        <tbody>
          <tr v-for="result in results" :key="result.id">
            <td>{{ result.userName }}</td>
            <td>{{ result.companyCat }}</td>
            <td>
              {{
                result.createDay.split("T")[0].split("-")[2] +
                  "-" +
                  result.createDay.split("T")[0].split("-")[1] +
                  "-" +
                  result.createDay.split("T")[0].split("-")[0]
              }}
            </td>
            <td style="text-align: center">
              <b-button
                variant="outline-success"
                @click="downloadWithAxios(result.id)"
              >
                <b-icon icon="arrow-down" aria-hidden="true"></b-icon>
                Excel Export
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import requests from "@/requests";

export default {
  name: "sonuclar",
  data() {
    return {
      results: [],
    };
  },

  methods: {
    downloadWithAxios(id) {
      axios({
        url: requests.getExcelExport,
        method: "post",
        responseType: "arraybuffer",
        data: {
          sessionid: id,
        },
        headers: {
          Authorization:
            `Bearer ` + localStorage.getItem("token").split('"')[1],
        },
      }).then((response) => {
        response.data;
      });
    },
  },

  async created() {
    await axios
      .post(
        requests.getCrawlingResults,
        {},
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("token").split('"')[1],
          },
        }
      )
      .then((e) => {
        this.results = e.data.isCreated;
      });
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
