<template>
  <div class="card card-container">
    <img
      id="profile-img"
      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
      class="profile-img-card"
    />
    <form name="form" @submit.prevent="handleLogin">
      <div class="form-group">
        <label>Username</label>
        <input
          v-model="user.username"
          type="text"
          class="form-control"
          name="username"
          required
        />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input
          v-model="user.password"
          type="password"
          class="form-control"
          name="password"
          required
        />
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-block" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Login</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      user: { username: "", password: "" },
      loading: false,
      errorMessage: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/ara");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      if (this.user.username && this.user.password) {
        this.$store
          .dispatch("auth/login", this.user)
          .then(() => {
            this.$router.push("/ara");
          })
          .catch(() => {
            this.makeToast("error", "Bilgilerinizi kontrol ediniz");
            this.loading = false;
          });
      }
    },
    makeToast(variant, message) {
      this.$bvToast.toast(message, {
        title: `${variant}`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  width: 350px;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 100px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
