<template>
  <div
    style="background: #eef0f3; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); padding: 0px 3vw;"
  >
    <b-form inline @submit.prevent="onSubmit">
      <div
        style="display:flex; flex-direction: column; justify-content: center;width:100%; height:200px;align-items:center; padding: 10px;"
      >
        <!-- <div style=" margin-bottom:10px">
        <b-form-input
          id="inline-form-input-name"
          class="mr-2"
          placeholder="Ne aramak istiyorsunuz?"
          v-model="toSearch"
        ></b-form-input>

        <b-button
          variant="outline-danger"
          type="submit"
          @click="stopSearching"
          :disabled="!isLoading"
        >
          <b-icon icon="x" scale="2"></b-icon>
          İptal Et
        </b-button>
      </div> -->
        <div
          style="display: flex; align-items: center; justify-content: center; width: 100%;"
        >
          <b-form-select
            v-model="selectedCity"
            :options="cities"
            class="mr-2"
          ></b-form-select>
          <b-form-select
            v-model="selectedSector"
            :options="sectors"
            class="mr-2"
          ></b-form-select>
          <b-form-select
            v-model="selectedCrossBorder"
            :options="crossBorder"
            class="mr-2"
          ></b-form-select>
          <b-button
            variant="outline-success"
            type="submit"
            @click="search"
            :disabled="isLoading"
          >
            <b-icon
              icon="search"
              scale="1"
              v-show="!isLoading"
              class="mr-1"
            ></b-icon>
            <span v-if="!isLoading">Ara</span>
            <span
              v-show="isLoading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-if="isLoading" class="ml-1">Aranıyor..</span>
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from "@/axios";
import requests from "@/requests";

export default {
  name: "Ara",

  data() {
    return {
      isLoading: null,
      //toSearch: "",
      //isCanceled: "",
      intervalHandle: null,
      selectedCity: null,
      selectedSector: null,
      selectedCrossBorder: null,
      cities: [
        { value: null, text: "İl" },
        { value: "1", text: "Adana" },
        { value: "2", text: "Adıyaman" },
        { value: "3", text: "Afyonkarahisar" },
        { value: "4", text: "Ağrı" },
        { value: "68", text: "Aksaray" },
        { value: "5", text: "Amasya" },
        { value: "6", text: "Ankara" },
        { value: "7", text: "Antalya" },
        { value: "75", text: "Ardahan" },
        { value: "8", text: "Artvin" },
        { value: "9", text: "Aydın" },
        { value: "10", text: "Balıkesir" },
        { value: "74", text: "Bartın" },
        { value: "72", text: "Batman" },
        { value: "69", text: "Bayburt" },
        { value: "11", text: "Bilecik" },
        { value: "12", text: "Bingöl" },
        { value: "13", text: "Bitlis" },
        { value: "14", text: "Bolu" },
        { value: "15", text: "Burdur" },
        { value: "16", text: "Bursa" },
        { value: "17", text: "Çanakkale" },
        { value: "18", text: "Çankırı" },
        { value: "19", text: "Çorum" },
        { value: "20", text: "Denizli" },
        { value: "21", text: "Diyarbakır" },
        { value: "81", text: "Düzce" },
        { value: "22", text: "Edirne" },
        { value: "23", text: "Elazığ" },
        { value: "24", text: "Erzincan" },
        { value: "25", text: "Erzurum" },
        { value: "26", text: "Eskişehir" },
        { value: "27", text: "Gaziantep" },
        { value: "28", text: "Giresun" },
        { value: "29", text: "Gümüşhane" },
        { value: "30", text: "Hakkari" },
        { value: "76", text: "Iğdır" },
        { value: "32", text: "Isparta" },
        { value: "34", text: "İstanbul" },
        { value: "35", text: "İzmir" },
        { value: "46", text: "Kahramanmaraş" },
        { value: "78", text: "Karabük" },
        { value: "70", text: "Karaman" },
        { value: "36", text: "Kars" },
        { value: "37", text: "Kastamonu" },
        { value: "38", text: "Kayseri" },
        { value: "71", text: "Kırıkkale" },
        { value: "39", text: "Kırklareli" },
        { value: "40", text: "Kırşehir" },
        { value: "79", text: "Kilis" },
        { value: "41", text: "Kocaeli" },
        { value: "42", text: "Konya" },
        { value: "43", text: "Kütahya" },
        { value: "44", text: "Malatya" },
        { value: "45", text: "Manisa" },
        { value: "47", text: "Mardin" },
        { value: "33", text: "Mersin" },
        { value: "48", text: "Muğla" },
        { value: "49", text: "Muş" },
        { value: "50", text: "Nevşehir" },
        { value: "51", text: "Niğde" },
        { value: "52", text: "Ordu" },
        { value: "80", text: "Osmaniye" },
        { value: "53", text: "Rize" },
        { value: "54", text: "Sakarya" },
        { value: "55", text: "Samsun" },
        { value: "56", text: "Siirt" },
        { value: "57", text: "Sinop" },
        { value: "58", text: "Sivas" },
        { value: "63", text: "Şanlıurfa" },
        { value: "73", text: "Şırnak " },
        { value: "59", text: "Tekirdağ" },
        { value: "60", text: "Tokat " },
        { value: "61", text: "Trabzon" },
        { value: "62", text: "Tunceli " },
        { value: "64", text: "Uşak" },
        { value: "65", text: "Van" },
        { value: "77", text: "Yalova " },
        { value: "66", text: "Yozgat" },
        { value: "67", text: "Zonguldak" },
      ],

      sectors: [
        { value: null, text: "Sektör" },
        { value: "19", text: "Elektronik" },
        { value: "20", text: "Ev Tekstili" },
        { value: "21", text: "Ev, Bahçe, Mobilya" },
        { value: "22", text: "Giyim, Ayakkabı ve Aksesuar" },
        { value: "23", text: "Kişisel Bakım ve Kozmetik" },
        { value: "24", text: "Spor ve Outdoor" },
        { value: "25", text: "Tekstil" },
        { value: "26", text: "Telekomünikasyon" },
        { value: "27", text: "Ambalaj ve Reklam" },
        { value: "28", text: "Anne, Bebek ve Oyuncak" },
        { value: "29", text: "Çiçekler" },
        { value: "30", text: "El işleri ve Hediyelik Eşya" },
        { value: "31", text: "Hobi, Eğlence ve Sanat" },
        { value: "32", text: "İnşaat ve Emlak" },
        { value: "33", text: "Kitap, Müzik, Film, Oyun" },
        { value: "34", text: "Makina, Endüstriyel Parça ve Araçları" },
        { value: "35", text: "Medikal Ürünler" },
        { value: "36", text: "Metalurji,Kimyasallar,Kauçuk ve  Plastik" },
        { value: "37", text: "Nakliyat ve Taşımacılık" },
        { value: "38", text: "Ofis ve Okul Malzemeleri" },
        { value: "39", text: "Online Eğitim" },
        { value: "40", text: "Otomotiv, yedek parça ve Aksesuarları" },
        { value: "41", text: "Süpermarket ve Petshop" },
        { value: "42", text: "Tadilat,Temizlik ve Organizasyon" },
        { value: "43", text: "Tatil ve Seyahat" },
        { value: "44", text: "Yiyecek ve İçecek" },
        { value: "45", text: "Danışmanlık Hizmetleri" },
        { value: "46", text: "Kupon, Kredi ve Hediye Çeki" },
        { value: "47", text: "Kuyumculuk" },
        { value: "48", text: "Matbaacılık ve Yayınlar" },
        { value: "49", text: "Yazılım" },
        { value: "50", text: "Eğitim" },
        { value: "51", text: "Tarım ve Hayvancılık Malzemeleri" },
        { value: "52", text: "Finansal Hizmetler" },
        { value: "53", text: "Araç Kiralama" },
        { value: "54", text: "Bahis ve Şans Oyunları" },
      ],

      crossBorder: [
        { value: null, text: "Sınır Ötesi" },
        { value: true, text: "Evet" },
        { value: false, text: "Hayır" },
      ],
    };
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/logout");
    }
    //else {
    //   this.checkIsSearching();
    //   this.intervalHandle = setInterval(this.checkIsSearching, 2000);
    // }
  },
  destroyed() {
    clearInterval(this.intervalHandle);
  },
  methods: {
    // checkIsSearching() {
    //   let prevIsLoading = this.isLoading;
    //   axios
    //     .get(requests.getIsSearching, {
    //       headers: {
    //         Authorization:
    //           "Bearer " + localStorage.getItem("token").split('"')[1],
    //       },
    //     })
    //     .then((e) => {
    //       this.isLoading = e.data.isCreated.isCrawling;
    //       if (this.isLoading) this.toSearch = e.data.isCreated.companyCat;

    //       if (!this.isLoading && prevIsLoading)
    //         this.makeToast("success", "Arama tamamlandı");
    //       prevIsLoading = this.isLoading;
    //     })
    //     .catch((err) => {
    //       if (err.response.status === 401) {
    //         this.$router.push("/logout");
    //       }
    //     });
    // },
    search() {
      this.isLoading = true;
      axios
        .post(
          requests.crawlWebsite,
          {
            provinceId: this.selectedCity == null ? 0 : this.selectedCity,
            category: this.selectedSector == null ? 0 : this.selectedSector,
            isOutBorder:
              this.selectedCrossBorder == null
                ? false
                : this.selectedCrossBorder,
          },
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("token").split('"')[1],
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.isLoading = false;
          window.location.href =
            "https://api.kvkkcrawler.production.cmosteknoloji.com/excelexports/" +
            res.data.isCreated;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$router.push("/logout");
          }
        });
    },
    // stopSearching() {
    //   axios
    //     .post(
    //       requests.stopCrawling,
    //       {},
    //       {
    //         headers: {
    //           Authorization:
    //             "Bearer " + localStorage.getItem("token").split('"')[1],
    //         },
    //       }
    //     )
    //     .then(() => {
    //       this.isLoading = false;
    //       this.makeToast("danger", "Arama iptal edildi");
    //     })
    //     .catch(() => {
    //       this.isLoading = false;
    //     });
    // },
    makeToast(variant, message) {
      this.$bvToast.toast(message, {
        title: `${variant}`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
