<template>
  <div>
    <Navbar name="Website Crawler"/>
    <div class="app">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar'

export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style scoped>
.app {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 60vh;
  width: 100%;
}
</style>

