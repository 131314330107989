import axios from 'axios';

const API_URL = 'https://api.kvkkcrawler.production.cmosteknoloji.com/api/';

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'Users/Login', {
                email: user.username,
                password: user.password
            })
            .then(response => {
                if (response.data.userInfo.token) {
                    localStorage.setItem('user', JSON.stringify(response.data.userInfo));
                    localStorage.setItem('token', JSON.stringify(response.data.userInfo.token));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

}

export default new AuthService();
