<template>
  <b-navbar
    variant="faded"
    type="light"
    style="justify-content: space-between !important;"
  >
    <b-navbar-brand tag="h1" class="mb-0">{{ name }}</b-navbar-brand>
    <div>
      <!-- <b-button
        variant="outline-primary"
        to="/sonuclar"
        v-show="this.$store.state.auth.status.loggedIn"
      >
        <b-icon icon="card-checklist" aria-hidden="true"></b-icon>
        Sonuclar
      </b-button> -->
      <b-button
        variant="outline-danger"
        to="/logout"
        class="ml-2"
        v-show="this.$store.state.auth.status.loggedIn"
      >
        <b-icon icon="power" aria-hidden="true"></b-icon>
        Logout
      </b-button>
    </div>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    name,
  },
};
</script>

<style scoped></style>
