import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Login';
import Logout from '@/components/Logout';
import Ara from "@/components/Ara";
import Sonuclar from "@/components/Sonuclar";

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
        },
        {
            path: '/ara',
            name: 'Ara',
            component: Ara
        },
        {
            path: '/sonuclar',
            name: 'Sonuclar',
            component: Sonuclar
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
        },
    ],
});
