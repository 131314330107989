<template>
  <div></div>
</template>

<script>
export default {
  name: "Logout",
  created() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    this.$store.dispatch("auth/logout");
    this.$router.push("/");
  },
};
</script>

<style scoped></style>
